<template>
  <vs-chip :class="['ag-grid-cell-chip', chipClass(params.data.bank)]">
    <span class="text-center" v-if="params.data.bank">{{
      labeler(params.data.bank)
    }}</span>
  </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererBank',
  computed: {
    chipClass() {
      const classes = {
        BANCOLOMBIA: 'bancolombia',
        NEQUI: 'nequi',
        AHORRO_A_LA_MANO: 'ahorro-a-la-mano',
      };

      return (value) => {
        return classes[value] ?? classes.DEFAULT;
      };
    },
    labeler() {
      const labels = {
        BANCOLOMBIA: 'Bancolombia',
        NEQUI: 'Nequi',
        AHORRO_A_LA_MANO: 'Ahorro a la mano',
      };

      return (value) => {
        return labels[value] ?? labels.DEFAULT;
      };
    },
  },
};
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  margin: 0 auto;
  @apply font-bold;
  &.bancolombia {
    background-color: #000000;

    .vs-chip--text {
      @apply text-white;
    }
  }

  &.nequi {
    background-color: #6f5ecf;
    .vs-chip--text {
      @apply text-white;
    }
  }

  &.ahorro-a-la-mano {
    background-color: #d7a92b;
    .vs-chip--text {
      @apply text-white;
    }
  }
}
</style>
