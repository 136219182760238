<template>
  <div>
    <vx-card>
      <div class="flex justify-between items-center gap-3">
        <div class="w-full">
          <label class="text-sm text-san-juan font-bold">Buscar</label>
          <vs-input
            class="mb-4 w-full md:mb-0 mr-4"
            v-model="searchQuery"
            placeholder="Buscar"
          />
        </div>
        <div class="flex gap-3">
          <vs-select
            label="Estado"
            class="w-full"
            v-model="statusSelected"
            placeholder="Seleccione un estado"
            autocomplete
          >
            <vs-select-item :value="'ALL'" :text="'Todos'" />
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in statuses"
            />
          </vs-select>
          <vs-select
            label="Contratistas"
            class="w-full"
            v-model="deliverymanSelected"
            placeholder="Seleccione un contratista"
            autocomplete
          >
            <vs-select-item :value="'ALL'" :text="'Todos'" />
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="`${item.code ? item.code : 'N/A'} - ${
                item.user.first_name + ' ' + item.user.last_name
              }`"
              v-for="(item, index) in deliverymen"
            />
          </vs-select>

          <div class="mt-6">
            <vs-button
              type="border"
              class="mb-4 md:mb-0 mr-3 whitespace-no-wrap"
              @click="exportBankAccounts"
            >
              Exportar
            </vs-button>
          </div>
        </div>
      </div>

      <section>
        <ValidationObserver ref="oberserverStatus" v-slot="{ invalid }">
          <vs-prompt
            :active.sync="togglePromptChangeStatus"
            title="Verificar Cuenta Bancaria"
            @accept="saveStatus(currentSelectedAccount)"
            accept-text="Guardar"
            :is-valid="!invalid"
            cancel-text="Descartar"
            v-if="currentSelectedAccount"
          >
            <vs-select class="w-full" label="Estado:" v-model="status">
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in statuses"
              />
            </vs-select>
          </vs-prompt>
        </ValidationObserver>
      </section>

      <div v-if="!loading && !accountsLoading">
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="agGridState.gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="agGridState.defaultColDef"
          :rowData="accounts"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :overlayLoadingTemplate="overlayLoadingTemplate"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="agGridState.maxPageNumbers"
          v-model="currentPage"
        />
      </div>
      <div v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { useAgGrid } from '@/composable/useAgGrid.js';
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/src/locale';

import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
import CellRendererPercentAmount from '@/components/cell-renderer/CellRendererPercentAmount.vue';
import CellRendererBank from './cell-renderer/CellRendererBank.vue';
import CellRendererImage from './cell-renderer/CellRendererImage.vue';
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue';

import { UPDATE_BANK_ACCOUNT_STATUS } from '@/graphql/mutations';
import { BANK_ACCOUNTS, DELIVERYMEN } from '@/graphql/queries.js';

export default defineComponent({
  components: {
    CellRendererAmount,
    CellRendererPercentAmount,
    CellRendererBank,
    CellRendererStatus,
    CellRendererImage,
    Datepicker,
  },
  setup(_, { root }) {
    const state = reactive({
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 70,
          filter: true,
        },
        {
          headerName: 'Estado',
          field: 'status',
          width: 150,
          filter: true,
          cellRendererFramework: 'CellRendererStatus',
        },
        {
          headerName: 'Código aliado',
          field: 'deliveryman.code',
          width: 130,
          filter: true,
        },
        {
          headerName: 'Nombre Aliado',
          field: 'deliveryman.user.first_name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Apellido Aliado',
          field: 'deliveryman.user.last_name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Nombre de la cuenta',
          field: 'name',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Banco',
          field: 'bank',
          width: 200,
          filter: true,
          cellRendererFramework: 'CellRendererBank',
        },
        {
          headerName: 'N. Cuenta',
          field: 'number',
          width: 200,
          filter: true,
        },
        {
          headerName: 'Doc. Identificación',
          field: 'document',
          width: 160,
          filter: true,
        },
        {
          headerName: 'Evidencia',
          field: 'image',
          width: 110,
          filter: true,
          cellRendererFramework: 'CellRendererImage',
        },
        {
          headerName: 'Fecha Creación',
          field: 'created_at',
          width: 190,
          filter: true,
        },
      ],
      loading: false,
      overlayLoadingTemplate:
        '<span>Please wait while your rows are loading</span>',

      currentSelectedAccount: null,
      statuses: [
        {
          text: 'Pendiente',
          value: 'PENDING',
        },
        {
          text: 'Aprobado',
          value: 'APPROVED',
        },
        {
          text: 'Denegado',
          value: 'DENIED',
        },
      ],
      togglePromptChangeStatus: false,
      status: null,
      deliverymanSelected: null,
      statusSelected: null,
    });

    const currentPage = ref(1);
    const first = ref(20);
    const searchQuery = ref('');
    const paginationPageSize = ref('');
    const deliverymanSelected = computed(() =>
      state.deliverymanSelected !== 'ALL' ? state.deliverymanSelected : null
    );
    const statusSelected = computed(() =>
      state.statusSelected !== 'ALL' ? state.statusSelected : null
    );

    const { result: accountsResult, loading: accountsLoading } = useQuery(
      BANK_ACCOUNTS,
      {
        page: currentPage,
        first: first,
        search: searchQuery,
        deliveryman_id: deliverymanSelected,
        status: statusSelected,
      }
    );
    const accounts = useResult(accountsResult, [], (data) => {
      console.log('✅ accountsResult ', data);
      return data.bankAccounts.data;
    });

    const { result: deliverymenResult, loading: deliverymanLoading } =
      useQuery(DELIVERYMEN);
    const deliverymen = useResult(deliverymenResult, [], (data) => {
      console.log('✅ deliverymenResult ', data);
      return data.deliverymen;
    });

    watch(accountsLoading, (value) => {
      state.loading = value;
    });
    watch(deliverymanLoading, (value) => {
      state.loading = value;
    });

    const paginatorInfo = useResult(accountsResult, [], (data) => ({
      total: data.bankAccounts.paginatorInfo.total,
      perPage: data.bankAccounts.paginatorInfo.perPage,
    }));

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );

    const { state: agGridState } = useAgGrid({ root });

    const changeStatus = ({ account }) => {
      state.currentSelectedAccount = account;
      state.status = state.currentSelectedAccount.status;
      state.togglePromptChangeStatus = true;
    };

    const saveStatus = async (account) => {
      try {
        state.loading = true;
        await root.$apollo.mutate({
          mutation: UPDATE_BANK_ACCOUNT_STATUS,
          variables: {
            input: {
              id: account.id,
              status: state.status,
            },
          },
        });
        root.$vs.notify({
          color: 'success',
          title: 'Correctamente Actualizado',
          text: 'Actualizaste la verificación correctamente.',
        });
        state.loading = false;
        state.currentSelectedAccount.status = state.status;
      } catch (error) {
        state.loading = false;
        console.log(error);
        root.$vs.notify({
          color: 'danger',
          title: 'Error',
          text: 'Comprueba tu conexión a internet.',
        });
      }
    };

    const exportBankAccounts = () => {
      const fileName = 'cuentas-bancarias.xlsx';
      root.$http
        .post(
          'api/bank-accounts/export',
          {
            search: searchQuery.value,
            deliveryman_id: deliverymanSelected.value,
            status: statusSelected.value,
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    return {
      ...toRefs(state),
      accountsLoading,
      paginationPageSize,
      agGridState,
      totalPages,
      currentPage,
      accounts,
      deliverymen,
      searchQuery,
      langEs: es,
      changeStatus,
      saveStatus,
      exportBankAccounts,
    };
  },
  created() {
    this.agGridState.gridOptions.context = {
      componentParent: this,
    };
  },
});
</script>
