<template>
  <vs-chip
    @click.native="changeStatus"
    :class="['ag-grid-cell-chip', chipClass(params.data.status)]"
  >
    <span class="text-center" v-if="params.data.status">{{
      labeler(params.data.status)
    }}</span>
  </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  computed: {
    chipClass() {
      const classes = {
        PENDING: 'pending',
        APPROVED: 'approved',
        DENIED: 'denied',
      };

      return (value) => {
        return classes[value] ?? classes.DEFAULT;
      };
    },
    labeler() {
      const labels = {
        PENDING: 'Pendiente',
        APPROVED: 'Aprobado',
        DENIED: 'Denegado',
      };

      return (value) => {
        return labels[value] ?? labels.DEFAULT;
      };
    },
  },
  methods: {
    changeStatus() {
      this.params.context.componentParent.changeStatus({
        status: this.params.value,
        account: this.params.data,
      });
    },
  },
};
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  margin: 0 auto;
  @apply font-bold;
  &.pending {
    background-color: #d7a92b;

    .vs-chip--text {
      @apply text-white;
    }
  }

  &.approved {
    background-color: #58c954;
    .vs-chip--text {
      @apply text-white;
    }
  }

  &.denied {
    background-color: #d73838;
    .vs-chip--text {
      @apply text-white;
    }
  }
}
</style>
